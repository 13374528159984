<template >
  <div class="containter">
    <img src="../../assets/img/gobackArrow.jpg" @click="gobackOne" class="gobackButton" />
    <div class="tabArea">
      <div class="height12"></div>
      <div class="commentsAvtorArea">
        <!-- <div class="commentsAvtor" v-if="!detail.applyAvatar"></div> -->
        <img class="commentsAvtor" :src="detail.applyAvatar" alt v-if="detail.applyAvatar" />
        <img class="commentsAvtor" v-else src="../../assets/img/avatar.png" alt />
      </div>
      <div class="commentsContentArea">
        <div class="commetUserAndDate">
          <div class="fontName">{{detail.feedbackName}}</div>
          <div class="fontDate">{{detail.typeName}}</div>
          <!-- <div class="fontDate">{{detail.feedbackTime}}</div> -->
        </div>
        <div class="fontContent">{{detail.feedbackContent}}</div>
        <div
          class="commentsImgLine"
          v-if="detail.imageUrl && detail.imageUrl.split(',').length > 0"
        >
          <img
            class="commentsImgItem"
            v-for="(imgItem, index) in detail.imageUrl.split(',')"
            :key="index"
            :src="imgItem"
            alt
          />
        </div>
        <div class="dateLine">{{detail.feedbackTime}}</div>
        <div class="height15"></div>
      </div>
    </div>
    <div class="height10" v-if="detail.status===1"></div>

    <div class="mainTitleLine">
      <span class="marginLeft10">处理结果</span>
      <span v-if="detail.status===0" class="redFont">未处理</span>
      <span v-if="detail.status===1" class="redFont">已处理</span>
    </div>
    <div v-if="detail.status===1">
      <div class="taskCatchLine">
        <span class="approveTitle">回复内容：</span>
        <span class="font12">{{detail.replyContent}}</span>
      </div>
      <div class="taskCatchLine">
        <span class="approveTitle">处理人：</span>
        <span class="font12">{{detail.handlerName}}</span>
      </div>
      <div class="taskCatchLine">
        <span class="approveTitle">处理时间：</span>
        <span class="font12">{{detail.handleTime|timeFilter}}</span>
      </div>
    </div>

    <div class="height15"></div>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { screenFeedbackDetail } from '@/api/bigScreen';

export default {
  name: 'SFDashboardDetail',

  data() {
    return {
      detail: {}
    };
  },
  filters: {
    timeFilter(time) {
      return time ? time.split(' ')[0] : '';
    }
  },
  watch: {},
  created() {
    this.getDetail();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getDetail() {
      const { detail } = this.$route.query;
      screenFeedbackDetail({
        id: detail
      }).then(res => {
        console.log(res);
        if (res.success) {
          this.detail = res.data;
        }
      });
    },
    approve() {
      const { id } = this.detail;
      this.$router.push({ name: 'situationFeedbackSubmit', query: { id: id } });
    },
    gobackOne() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: relative;
}
.height82 {
  width: 100%;
  height: 82 * $px;
}
.bottomZanLine {
  position: fixed;
  bottom: 0 * $px;
  left: 0 * $px;
  width: 100%;
  height: 74 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.bottomButtonTemp {
  height: 44 * $px;
  width: 345 * $px;
  border-radius: 22 * $px;
  font-size: 16 * $px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.redButton {
  background-color: #ff192f;
}
.tabArea {
  width: 100%;
  overflow: auto;
  position: relative;
}
.listCell {
  margin: 10 * $px 10 * $px 0 10 * $px;
  width: 100%;
  background-color: #ffffff;
}
.commentsAvtorArea {
  width: 45 * $px;
  float: left;
  display: flex;
  justify-content: center;
}
.commentsAvtor {
  width: 33 * $px;
  height: 33 * $px;
  background-color: grey;
  overflow: hidden;
  border-radius: 16.5 * $px;
  margin-left: 12 * $px;
}
.commentsContentArea {
  margin-left: 8 * $px;
  width: 322 * $px;
  float: left;
}
.commetUserAndDate {
  height: 15 * $px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontName {
  color: #555555;
  font-size: 14 * $px;
}
.fontDate {
  color: #999999;
  font-size: 11 * $px;
  margin-right: 10 * $px;
}
.fontContent {
  margin: 8 * $px 0 0 0;
  color: #222222;
  font-size: 14 * $px;
  padding-right: 20 * $px;
}
.commentsBottomLine {
  width: 100%;
  height: 0.5 * $px;
  background-color: #eeeeee;
  margin-top: 15 * $px;
}
.commentsImgLine {
  width: 100%;
  word-break: break-all;
  overflow: auto;
}
.commentsImgItem {
  height: 70 * $px;
  width: 70 * $px;
  margin-top: 9 * $px;
  margin-right: 10 * $px;
  background-color: #dddddd;
  overflow: hidden;
  float: left;
}
.height15 {
  width: 100%;
  height: 15 * $px;
}
.dealStatusLine {
  width: 100%;
  height: 30 * $px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18 * $px;
  color: #ff192f;
}
.dateLine {
  width: 100%;
  height: 13 * $px;
  font-size: 12 * $px;
  color: #999999;
  margin-top: 10 * $px;
}
.height12 {
  width: 100%;
  height: 12 * $px;
}
.height10 {
  width: 100%;
  height: 10 * $px;
  background-color: #f5f5f5;
}
.mainTitleLine {
  width: 100%;
  height: 46 * $px;
  color: #222222;
  font-size: 16 * $px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.marginLeft10 {
  margin-left: 10 * $px;
}
.taskCatchLine {
  margin-bottom: 9 * $px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14 * $px;
  color: #555555;
}
.approveTitle {
  margin-left: 12 * $px;
  flex-shrink: 0;
}
.font12 {
  color: #222222;
  font-size: 14 * $px;
}
.redFont {
  color: #ff192f;
  font-size: 12 * $px;
  margin-right: 12 * $px;
}
.greyFont {
  color: #999999;
  font-size: 12 * $px;
  margin-right: 12 * $px;
}
.gobackButton {
  width: 50 * $px;
  height: 34 * $px;
  position: absolute;
  bottom: 30 * $px;
  left: 0 * $px;
}
</style>



